@import 'styles/includes.scss';

.SearchModal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &__Inner {
        padding-top: 3.2rem;
        padding-bottom: 6.4rem;
        display: flex;
        align-items: center;
        position: relative;
        background-color: $colorBlack800;

        @include media(m) {
            padding-top: 4.8rem;
            padding-bottom: 4.8rem;
            justify-content: center;
        }
    }

    &__Container {
        @extend %container;
        width: 100%;
    }

    &__Wrapper {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
    }

    &__InputWrapper {
        width: 100%;

        @include media(m) {
            width: calc((832 / 1260) * 100%);
        }
    }

    &__CloseModal {
        position: absolute;
        padding: .2rem 2.2rem .2rem .2rem;
        @extend %body-2;
        text-decoration: underline;
        font-size: 12px;
        line-height: 19.2px;
        color: $colorGray10;
        background: none;
        border: none;
        right: 0;
        bottom: calc(-24px - 20px);

        @include media(m) {
            padding: .4rem 2.8rem .4rem .4rem;
            line-height: 1.65;
            font-size: 16px;
            top: 50%;
            transform: translateY(-50%);
        }

        @include media(ls) {
            margin-right: 2.4rem;
        }
    }

    &__CloseIcon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        svg {
            width: 16px;
            height: 16px;

            @include media(m) {
                width: 20px;
                height: 20px;
            }
        }
    }
}
